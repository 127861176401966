import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './CMFWS.css';
import MyApp from './components/TimeTable';
import DialogResponse from './components/DialogResponse';
import axios from 'axios';
import { opendir } from 'fs';


interface Walk {
    attendee: string,
    tour: string
};

function isWalk(walk: Walk, value: string) {
    return walk.tour === value;
}

function CMFWorkshop() {

    const [open, setOpen] = React.useState(true);
    var dialogval;
    var openDialog = false;
    if (window.location.href.indexOf('rc=0') > 0) {
        dialogval = 0;
        openDialog = true;
    }

    if (window.location.href.indexOf('rc=1') > 0) {
        dialogval = 1;
        openDialog = true;
    }


    // State to store the fetched data
    const [data, setData] = useState<any>(null);
    // useEffect to fetch data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Make an API request using fetch or axios
                // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
                //const response = await axios.get('http://localhost:5254/Polls');
                const response = await axios.get('https://designpost.digitalscope.de/API/Polls');


                // Update the state with the fetched data
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();
    }, []); // The empty dependency arra

    /*const useJson = async () => {
    const useTest=  await data.json();
    useState<Walk>({attendee:useJson.walks[0].attendee, tour:useJson.walks[0].tour})
    console.log(useJson);*/

    if (data != null) {
        var day1w1 = data.walks.find((element: Walk) => element.tour === "15.01.24 - Walk I");
        var day1w2 = data.walks.find((element: Walk) => element.tour === "15.01.24 - Walk II");
        var day2w1 = data.walks.find((element: Walk) => element.tour === "16.01.24 - Walk I");
        var day2w2 = data.walks.find((element: Walk) => element.tour === "16.01.24 - Walk II");
        var day3w1 = data.walks.find((element: Walk) => element.tour === "17.01.24 - Walk I");
        var day3w2 = data.walks.find((element: Walk) => element.tour === "17.01.24 - Walk II");
        var day4w1 = data.walks.find((element: Walk) => element.tour === "18.01.24 - Walk I");
        var inspDayMarch = data.walks.find((element: Walk) => element.tour === "18.03.24 - CMF Workshop Gudy Herder");
    }

    return (


        <div className="App">
            {openDialog ? <DialogResponse t={dialogval}></DialogResponse> : ""}
            <div className="header">
                <div className="logoTop">
                    <img src={logo} alt="logo" />
                </div>
                <h1>INSPIRATION DAY</h1>
                <p>Design Post Event. Wir freuen uns auf Ihren Besuch!</p>
            </div>

            <div className="topnav">
                {/*}<a href="#">Link</a>-->*/}&nbsp;
            </div>

            <div className="row">
                <div className="leftcolumn">
                    <div className="card">
                        <h2>Montag, 18. März 2024</h2>
                        <h4>CMF Workshop | Gudy Herder 14:00 – 17:00 Uhr *</h4>
                        <h4>Patio Space | Design Post Köln</h4>
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td><div className='pleft'>
                                    Politopia | Farbe, Materialien & Oberfläche<br />
                                    3 Farb-, Material- und Oberflächen-Trends für 2025<br />
                                    Emotionale Materialität entdecken
                                </div>
                                    <div className='pleft'>
                                        <p>
                                        <b>Inhalt</b><br />
                                        Gudy Herder stellt während des Inspiration Days in der Design Post Köln drei Richtungen innerhalb des faszinierenden und schnell wachsenden Feldes von CMF (Farbe, Material, Finish) in einer Keynote-Präsentation und einem interaktiven Workshop vor.
                                        Im Workshop werden 3 Macro-Trends (Well being, nature, urbanization) erörtert. Den Workshop-Teilnehmern werden dazu innovative Materialien anhand von angeleiteten Übungen gezeigt und sie können mit den Materialien interagieren. Während des Workshops tauchen Sie in die Welt der emotionalen Materialität ein und analysieren gleichzeitig die Rolle, die Farbe bei der Materialwahl spielt.
                                        </p>
                                    </div><div className='pleft'>
                                        <b>Zielgruppe</b><br />
                                        Für Pressevertreter, Designer, Architekten, Marketingfachleute, Veranstaltungsplaner und alle, die in der Innenarchitektur, im Einzelhandel und in der Kreativbranche tätig sind.
                                    </div><div className='pleft'><p>
                                        <b>Dauer</b><br />
                                        180 Minuten.
                                    </p></div>
                                </td>
                                    <td><img className='rtimage' src={require('./assets/GudyHerder_EclecticTrends_horizontal_Web_800.jpg')} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='center maxWidth1050'>

                            {inspDayMarch ?
                                ((Number(inspDayMarch.attendees)) < 22 ? (<MyApp t="8"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                                : <MyApp t="8"></MyApp>}
                            
                        </div>

                        <p>
                            <div>* Änderungen vorbehalten!</div>
                        </p>
                    </div>

                    {/**************************************/}

                </div>
                <div className="rightcolumn">
                    <div className="card">
                        <h2>About</h2>
                        <p>
                            INSPIRATION DAY<br />
                            <br />
                            Montag, 18. März 10:00 – 19:00 Uhr</p>

                        <p>
                            <b>Design Post Köln</b><br />
                            Deutz-Mülheimer-Str. 22a<br />
                            50679 Köln

                        </p>
                    </div>
                    { }
                </div>
            </div>

            <div className="footer">
                {/* <h2>Footer</h2>*/}
            </div>

        </div>
    );
}

export default CMFWorkshop;


function getData() {
    fetch("https://designpost.digitalscope.de/API/Polls",
        //fetch("http://localhost:5254/Polls",
        {
            method: "Get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(
            response => {
                response.json().then(function (result) {
                    return result;
                })
            })
};
