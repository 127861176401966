import React, { useEffect, useId, useState } from 'react';
import '../App.css';

import {
  makeStyles,
  FluentProvider,
  Button,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Label,
  Input,
  shorthands
} from "@fluentui/react-components";
import {
  bundleIcon,
  CalendarMonthFilled,
  CalendarMonthRegular,
} from "@fluentui/react-icons";
import type { Theme } from "@fluentui/react-components";


const myNewTheme: BrandVariants = {
  10: "#060202",
  20: "#261012",
  30: "#42161D",
  40: "#591A25",
  50: "#711C2E",
  60: "#891D37",
  70: "#A31E40",
  80: "#BD1C4A",
  90: "#D81953",
  100: "#EF255F",
  110: "#F54D70",
  120: "#FA6A81",
  130: "#FE8393",
  140: "#FF9CA6",
  150: "#FFB3B9",
  160: "#FFC9CD"
};

const lightTheme: Theme = {
  ...createLightTheme(myNewTheme),
};

const darkTheme: Theme = {
  ...createDarkTheme(myNewTheme),
};


darkTheme.colorBrandForeground1 = myNewTheme[110];
darkTheme.colorBrandForeground2 = myNewTheme[120];

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
});



export default function DialogResponse(t: any) {    
    var dialogval = t.t;
  const [open, setOpen] = React.useState(true);

  const styles = useStyles();
  return (
    <div>

        <FluentProvider theme={lightTheme} dir="rtl">
          <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
            <FluentProvider theme={lightTheme} dir="ltr">
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Hinweis</DialogTitle>
                  <DialogContent>
                    { dialogval == 0 ? "Ein Besucher mit der E-Mail Adresse ist bereits für einen Walk registriert!" : "Vielen Dank. Sie wurden registriert!"}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" onClick={openRoot}>Close</Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </FluentProvider>
          </Dialog>
        </FluentProvider> 
    </div>
  );
}

function openRoot() {
   // window.location.href = "http://localhost:3000";
   window.location.href = "https://designpost.digitalscope.de";

}
