import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './Polls.css';
import MyApp from './components/TimeTable';
import DialogResponse from './components/DialogResponse';
import axios from 'axios';
import { opendir } from 'fs';


interface Walk {
  attendee: string,
  tour: string
};

function isWalk(walk: Walk, value: string) {
  return walk.tour === value;
}

function Polls() {

  const [open, setOpen] = React.useState(true);
  var dialogval;
  var openDialog = false;
  if(window.location.href.indexOf('rc=0') > 0) {
    dialogval = 0;
    openDialog = true;
  }

  if(window.location.href.indexOf('rc=1') > 0) {
    dialogval = 1;
    openDialog = true;
  }


  // State to store the fetched data
  const [data, setData] = useState<any>(null);
  // useEffect to fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an API request using fetch or axios
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        //const response = await axios.get('http://localhost:5254/Polls');
        const response = await axios.get('https://designpost.digitalscope.de/API/Polls');
        

        // Update the state with the fetched data
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // The empty dependency arra

  /*const useJson = async () => {
  const useTest=  await data.json();
  useState<Walk>({attendee:useJson.walks[0].attendee, tour:useJson.walks[0].tour})
  console.log(useJson);*/

  if (data != null) {
    var day1w1 = data.walks.find((element: Walk) => element.tour === "15.01.24 - Walk I");
    var day1w2 = data.walks.find((element: Walk) => element.tour === "15.01.24 - Walk II");
    var day2w1 = data.walks.find((element: Walk) => element.tour === "16.01.24 - Walk I");
    var day2w2 = data.walks.find((element: Walk) => element.tour === "16.01.24 - Walk II");
    var day3w1 = data.walks.find((element: Walk) => element.tour === "17.01.24 - Walk I");
    var day3w2 = data.walks.find((element: Walk) => element.tour === "17.01.24 - Walk II");
    var day4w1 = data.walks.find((element: Walk) => element.tour === "18.01.24 - Walk I");
  }

  return (


    <div className="App">
      { openDialog ? <DialogResponse t={dialogval}></DialogResponse> : "" }
             <div className="header">
        <div className="logoTop">
          <img src={logo} alt="logo" />
        </div>
        <h1>Design Post Walks</h1>
        <p>Wir freuen uns auf Ihren Besuch!</p>
      </div>

      <div className="topnav">
        {/*}<a href="#">Link</a>-->*/}&nbsp;
      </div>

      <div className="row">
        <div className="leftcolumn">
          <div className="card">
            <h2>Montag, 15. Januar 2024</h2>
            <h4>WALK I - Koop. bdia 09.30 - 11:00 | 13:30Uhr *</h4>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>09:30</td>
                  <td>09:55</td>
                  <td>Création Baumann</td>
                </tr>
                <tr>
                  <td>10:00</td>
                  <td>10:25</td>
                  <td>Tecta</td>
                </tr>
                <tr>
                  <td>10:30</td>
                  <td>11:00</td>
                  <td>Zeitraum</td>
                </tr>
                <tr>
                  <td>11:00</td>
                  <td></td>
                  <td>Übergang zur Messe</td>
                </tr>
                <tr>
                  <td>11:30</td>
                  <td>13:00</td>
                  <td>Halle 4.2/3.2/1.2</td>
                </tr>
              </tbody>
            </table>
            <div className='center maxWidth1050'>

              {day1w1 ?
                ((Number(day1w1.attendees)) < 40 ? (<MyApp t="1"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="1"></MyApp>}
              Anmeldungen: {day1w1 ? (day1w1.attendees) : (0)} von 40


            </div>
            <h5>WALK II - 16:30 - 19:00 Uhr</h5>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>16:30</td>
                  <td></td>
                  <td>Empfang</td>
                </tr>
                <tr>
                  <td>16:45</td>
                  <td>17:00</td>
                  <td>THONET</td>
                </tr>
                <tr>
                  <td>17:10</td>
                  <td>17:45</td>
                  <td>STRING</td>
                </tr>
                <tr>
                  <td>17:45</td>
                  <td>18:05</td>
                  <td>ELMO</td>
                </tr>
                <tr>
                  <td>18:10</td>
                  <td>18:25</td>
                  <td>Kristalia</td>
                </tr>
                <tr>
                  <td>18:30</td>
                  <td>19:00</td>
                  <td>Prostoria</td>
                </tr>
              </tbody>
            </table>

            <div className='center maxWidth1050'>

              {day1w2 ?
                ((Number(day1w2.attendees)) < 40 ? (<MyApp t="2"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="2"></MyApp>}
              Anmeldungen: {day1w2 ? (day1w2.attendees) : (0)} von 40

            </div>

          </div>

          {/**************************************/}

          <div className="card">
            <h2>Dienstag, 16. Januar 2024</h2>
            <h4>WALK I - 09.30 - 12:00</h4>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>09:30</td>
                  <td></td>
                  <td>Akkreditierung</td>
                </tr>
                <tr>
                  <td>09:45</td>
                  <td>10:05</td>
                  <td>SAHCO & kvadrat</td>
                </tr>
                <tr>
                  <td>10:10</td>
                  <td>10:25</td>
                  <td>agape</td>
                </tr>
                <tr>
                  <td>10:30</td>
                  <td>10:50</td>
                  <td>arco</td>
                </tr>
                <tr>
                  <td>10:55</td>
                  <td>11:15</td>
                  <td>TOM DIXON</td>
                </tr>
                <tr>
                  <td>11:15</td>
                  <td>11:30</td>
                  <td>habit</td>
                </tr>
                <tr>
                  <td>11:30</td>
                  <td>12:00</td>
                  <td>nunc</td>
                </tr>
              </tbody>
            </table>
            <div className='center maxWidth1050'>

              {day2w1 ?
                ((Number(day2w1.attendees)) < 40 ? (<MyApp t="3"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="3"></MyApp>}
              Anmeldungen: {day2w1 ? (day2w1.attendees) : (0)} von 40

            </div>
            <h5>WALK II - 17:00 - 18:30 Uhr</h5>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17:00</td>
                  <td>17:20</td>
                  <td>SECTO Design</td>
                  <td></td>
                </tr>
                <tr>
                  <td>17:25</td>
                  <td>17:45</td>
                  <td>TUBES</td>
                </tr>
                <tr>
                  <td>17:50</td>
                  <td>18:20</td>
                  <td>Woodnotes | Nikari</td>
                </tr>
              </tbody>
            </table>

            <div className='center maxWidth1050'>
              {day2w2 ?
                ((Number(day2w2.attendees)) < 40 ? (<MyApp t="4"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="4"></MyApp>}
              Anmeldungen: {day2w2 ? (day2w2.attendees) : (0)} von 40


            </div>

          </div>


          {/**************************************/}

          <div className="card">
            <h2>Mittwoch, 17. Januar 2024</h2>
            <h5>WALK I - 09.30 - 11:00 | 13:30</h5>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>09:00</td>
                  <td></td>
                  <td>Akkreditierung</td>
                </tr>
                <tr>
                  <td>09:30</td>
                  <td>09:45</td>
                  <td>buschfeld</td>
                </tr>
                <tr>
                  <td>09:50</td>
                  <td>10:10</td>
                  <td>Quasar</td>
                </tr>
                <tr>
                  <td>10:15</td>
                  <td>10:40</td>
                  <td>Wünder/Weishäupl</td>
                </tr>
                <tr>
                  <td>10:45</td>
                  <td>11:00</td>
                  <td>SIXINCH</td>
                </tr>
                <tr>
                  <td>11:05</td>
                  <td></td>
                  <td>Übergang zur Messe</td>
                </tr>
                <tr>
                  <td>11:30</td>
                  <td>13:00</td>
                  <td>Halle 4.2/3.2/1.2</td>
                </tr>
              </tbody>
            </table>
            <div className='center maxWidth1050'>
              {day3w1 ?
                ((Number(day3w1.attendees)) < 40 ? (<MyApp t="5"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="5"></MyApp>}
              Anmeldungen: {day3w1 ? (day3w1.attendees) : (0)} von 40


            </div>
            <h5>WALK II - 17:00 - 18:45 Uhr</h5>
            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17:00</td>
                  <td>17:25</td>
                  <td>rohi</td>
                </tr>
                <tr>
                  <td>17:30</td>
                  <td>17:45</td>
                  <td>Sammode</td>
                </tr>
                <tr>
                  <td>17:45</td>
                  <td>18:00</td>
                  <td>Misura Rugs</td>
                </tr>
                <tr>
                  <td>18:05</td>
                  <td>18:25</td>
                  <td>System 180</td>
                </tr>
                <tr>
                  <td>18:30</td>
                  <td>18:45</td>
                  <td>Marset</td>
                </tr>
              </tbody>
            </table>

            <div className='center maxWidth1050'>

              {day3w2 ?
                ((Number(day3w2.attendees)) < 40 ? (<MyApp t="6"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="6"></MyApp>}
              Anmeldungen: {day3w2 ? (day3w2.attendees) : (0)} von 40


            </div>

          </div>

          {/**************************************/}

          <div className="card">
            <h2>Donnerstag, 18. Januar 2024</h2>
            <h5>WALK I - 09.30 - 11:00 | 13:30</h5>

            <table className="styled-table center">
              <thead>
                <tr>
                  <th colSpan={2}>Zeitraum</th>
                  <th>Marke/Station</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>09:00</td>
                  <td></td>
                  <td>Akkreditierung</td>
                </tr>
                <tr>
                  <td>09:30</td>
                  <td>09:50</td>
                  <td>Lodes</td>
                </tr>
                <tr>
                  <td>09:50</td>
                  <td>10:10</td>
                  <td>Wagner Living</td>
                </tr>
                <tr>
                  <td>10:10</td>
                  <td>10:25</td>
                  <td>HEY - Sign</td>
                </tr>
                <tr>
                  <td>10:30</td>
                  <td>10:45</td>
                  <td>pCon</td>
                </tr>
                <tr>
                  <td>10:45</td>
                  <td>11:00</td>
                  <td>Chatboard</td>
                </tr>
                <tr>
                  <td>11:30</td>
                  <td></td>
                  <td>Übergang zur Messe</td>
                </tr>
                <tr>
                  <td>11:30</td>
                  <td>13:00</td>
                  <td>Halle 4.2/3.2/1.2</td>
                </tr>
              </tbody>
            </table>
            <div className='center maxWidth1050'>

              {day4w1 ?
                ((Number(day4w1.attendees)) < 40 ? (<MyApp t="7"></MyApp>) : "Maximale Teilnehmerzahl erreicht. ")
                : <MyApp t="7"></MyApp>}
              Anmeldungen: {day4w1 ? (day4w1.attendees) : (0)} von 40


            </div>
            <p>
              <div>* Änderungen an Inhalten/Stationen der Walks vorbehalten!</div>
            </p>
          </div>

          {/**************************************/}

        </div>
        <div className="rightcolumn">
          <div className="card">
            <h2>About</h2>
            <p>
              Design Post Köln x imm cologne<br />
              14. - 18. Januar 2024</p><p>
              Öffnungszeiten zur imm<br />
              So. 14.01. | 09:00 - 21:00 Uhr<br />
              Mo. 15.01. - Mi. 17.01. | 09:00 - 19:00 Uhr<br />
              Do. 18.01. | 09:00 - 17:00 Uhr<br />

              Mehr als 30 internationale Marken auf 3.500qm Ausstellungsfläche</p>
            <p>
              <b>Design Post Köln</b><br />
              Deutz-Mülheimer-Str. 22a<br />
              50679 Köln

            </p>
          </div>
          {/*<div className="card">
      <h3>Popular Post</h3>
      <div className="fakeimg"><p>Image</p></div>
      <div className="fakeimg"><p>Image</p></div>
      <div className="fakeimg"><p>Image</p></div>
    </div>
    <div className="card">
      <h3>Follow Me</h3>
      <p>Some text..</p>
</div>*/}
        </div>
      </div>

      <div className="footer">
        {/* <h2>Footer</h2>*/}
      </div>

    </div>
  );
}

export default Polls;


function getData() {
  fetch("https://designpost.digitalscope.de/API/Polls",
  //fetch("http://localhost:5254/Polls",
    {
      method: "Get",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(
      response => {
        response.json().then(function (result) {
          return result;
        })
      })
};
