import React, { useState, useEffect, createElement } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Polls from './Polls';
import CMFWorkshop from './CMFWS';

export default function App() {
    return (
      <BrowserRouter>
        <Routes>

            <Route index element={<CMFWorkshop />} />
            <Route path="/polls" element={<Polls />} />

        </Routes>
      </BrowserRouter>
    );
  }
const domNode = document.getElementById('root')!;
const root = ReactDOM.createRoot(domNode);

  root.render(<App />)