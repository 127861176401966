import React, { useEffect, useId, useState } from 'react';
import { AttendeeForm } from './AttendeeForm';
import '../App.css';

import {
  makeStyles,
  FluentProvider,
  Button,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Label,
  Input,
  shorthands
} from "@fluentui/react-components";
import {
  bundleIcon,
  CalendarMonthFilled,
  CalendarMonthRegular,
} from "@fluentui/react-icons";
import type { Theme } from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";
import axios from 'axios';

const CalendarMonth = bundleIcon(CalendarMonthFilled, CalendarMonthRegular);

const myNewTheme: BrandVariants = {
  10: "#060202",
  20: "#261012",
  30: "#42161D",
  40: "#591A25",
  50: "#711C2E",
  60: "#891D37",
  70: "#A31E40",
  80: "#BD1C4A",
  90: "#D81953",
  100: "#EF255F",
  110: "#F54D70",
  120: "#FA6A81",
  130: "#FE8393",
  140: "#FF9CA6",
  150: "#FFB3B9",
  160: "#FFC9CD"
};

const lightTheme: Theme = {
  ...createLightTheme(myNewTheme),
};

const darkTheme: Theme = {
  ...createDarkTheme(myNewTheme),
};


darkTheme.colorBrandForeground1 = myNewTheme[110];
darkTheme.colorBrandForeground2 = myNewTheme[120];

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
});



export default function MyApp(t: any) {
  var initialProfile = {
    EMail: "",
    Vorname: "",
    Nachname: "",
    Tour: "",
    Berufsbezeichnung: "",
    Unternehmen: ""
  };
  const [hasBeenSend, setHasBeenSend] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [profileFields, setProfileFields] = useState(initialProfile);

  const { EMail, Vorname, Nachname, Tour, Berufsbezeichnung, Unternehmen } = profileFields;

  //console.log("profile fields: ", profileFields);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setProfileFields(() => {
      return {
        ...profileFields,
        [name]: value
      };
    });
  };

  const checkEmail = (event: any) => {
    //let url = 'http://localhost:5254/Attendee?email=' + event.EMail;
    let url = 'https://designpost.digitalscope.de/API/Attendee?email=' + event.EMail;
    
    var edata = event;
    const fetchData = async(url: string) =>
  {
    return await axios.get(url).then(res => getData(res, edata) /*sendData(event)*/)};

  const data = fetchData(url);
  console.log(`Data: ${data}`);
  
}

  const getData = (res:any,event:any) => {
   /* if(res.data) {
      //window.location.href = "http://localhost:3000?rc=0"
      window.location.href = "https://designpost.digitalscope.de?rc=0";
    }
    else {*/
      sendData(event);
   // }
  }

  const sendData =async (values:any) => {
       /*await axios.post("http://localhost:5254/Polls", values)
      .then((response) => {
        console.log(response);
        window.location.href = "http://localhost:3000?rc=1"
      });*/

       await axios.post("https://designpost.digitalscope.de/API/Polls", values)
      .then((response) => {
        console.log(response);
        window.location.href = "https://designpost.digitalscope.de?rc=1"
      });

  }

  if (t.t == "1") {

    initialProfile.Tour = "15.01.24 - Walk I"


  }
  if (t.t == "2") {

    initialProfile.Tour = "15.01.24 - Walk II"

  }

  if (t.t == "3") {

    initialProfile.Tour = "16.01.24 - Walk I"

  }
  if (t.t == "4") {

    initialProfile.Tour = "16.01.24 - Walk II"

  }

  if (t.t == "5") {

    initialProfile.Tour = "17.01.24 - Walk I"

  }
  if (t.t == "6") {

    initialProfile.Tour = "17.01.24 - Walk II"

  }

  if (t.t == "7") {

    initialProfile.Tour = "18.01.24 - Walk I"

  }
  if (t.t == "8") {

    initialProfile.Tour = "18.03.24 - CMF Workshop Gudy Herder"

  }


  const styles = useStyles();
  return (
    <div>

      {!hasBeenSend &&
        <FluentProvider theme={lightTheme} dir="rtl">
          <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
            <DialogTrigger disableButtonEnhancement>
              <div className='center'>
                <Button className='tableButton' appearance="primary" icon={<CalendarMonthRegular />}>
                  Anmelden
                </Button>
              </div>
            </DialogTrigger>
            <FluentProvider theme={lightTheme} dir="ltr">
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Anmeldeformular {initialProfile.Tour}</DialogTitle>
                  <DialogContent>
                    Bitte füllen Sie die folgenden Felder aus:
                    <p>
                      <table>
                        <tr>
                          <td><Label>
                            E-Mail:
                          </Label></td>
                          <td><Input name='EMail' id={"EMail"} onChange={handleChange} />
                          </td>
                        </tr>
                        <tr>
                          <td><Label>
                            Vorname:
                          </Label></td>
                          <td>
                            <Input name='Vorname' id={"Vorname"} onChange={handleChange} /><br />
                          </td></tr>
                        <tr>
                          <td><Label>
                            Nachname:
                          </Label></td>
                          <td>
                            <Input name='Nachname' id={"Nachname"} onChange={handleChange} /><br />
                          </td>
                        </tr>
                        <tr>
                          <td><Label>
                            Berufsbezeichnung
                          </Label></td>
                          <td>
                            <Input name='Berufsbezeichnung' id={"Berufsbezeichnung"} onChange={handleChange} /><br />
                          </td>
                        </tr>
                        <tr>
                          <td><Label>
                            Unternehmen
                          </Label></td><td>
                            <Input name='Unternehmen' id={"Unternehmen"} onChange={handleChange} /><br />
                          </td>
                        </tr>
                      </table>
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button onClick={() => checkEmail(profileFields)} appearance="primary">Teilnehmen</Button>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </FluentProvider>
          </Dialog>
        </FluentProvider>

      }
      {hasBeenSend &&
        <div>Wurde gesendet!</div>}
    </div>
  );
}
